.row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row-2 {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  
  .row-3 {
    margin-bottom: 10px;
  }

  .number {
    margin-right: 40px;
    margin-left: 40px;
    width: 100px; /* Adjust based on your design */  
    height: 100px; /* Adjust based on your design */
  }

  /* Assuming each button has a class "btn" for common styling */
  .btn {
    background-color: #c3b642; /* Khaki background */
    color: white;
    border-radius: 50%; /* Circular buttons */
    padding: 15px;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: rgb(43, 51, 41);
    border-width: 2px;
  }

  .btn:hover {
    background-color: #a9b491;
    border-color: rgb(43, 51, 41);
    border-width: 2px;
    color: white;
  }

  .special-button {
    width: 100px;
    margin: 5px;
    background-color: #6a911a;
  }
  
  .operator {
    background-color: #9acd32; /* Yellow-green background */
    width: 80px; /* Adjust based on your design */  
    height: 80px; /* Adjust based on your design */
    margin: 5px;
    margin-bottom: 15px;
  }
  
  .reset {
    border-radius: 0; /* Square button */
    background-color: #ff6347; /* Tomato background */
    cursor: pointer;
  }

  .reset:hover {
    background-color: #ff0000;
    border-color: rgb(43, 51, 41);
    border-width: 2px;
    color: white;
  }

  .scramble { 
    border-radius: 0;
    background-color: rgb(167, 167, 242);
    cursor: pointer;
  }

  .scramble:hover {
    background-color: rgb(0, 0, 255);
    border-color: rgb(43, 51, 41);
    border-width: 2px;
    color: white;
  }

  .selected {
    background-color: #55711c;
  }
  
  .hidden {
    visibility: hidden;
  }

  /* You may want to adjust the font size, width, and height to fit your design */
  