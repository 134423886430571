/* put everything in one column */
.center-horizontal {
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  margin: 0;
  padding: 0;
} 

.online24-title {
  margin-top: 5%;
}

.no-border {
  margin: 0;
}

.login-side {
  justify-content: center;
  background-color: rgb(204, 246, 243);
}

.players-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

.top-bottom {
  flex: 1;
  width: 100%;
}

.player-list-container {
  width: 90%;
  margin-left: 5%;
  margin-top: 20px;
  min-height: 140px;
  max-height: 380px;
  overflow-y: auto;
  background-color: rgb(64, 104, 104);
  border-radius: 10px;
  border: 3px solid rgb(238, 247, 250);
  color: rgb(238, 247, 250);
  padding: 10px; /* Adjust padding as needed */
}

.player-chat {
  background-color: rgb(146, 193, 193);
}

.player-list-title {
  padding-top: 5px;
  padding-bottom: 5px;
}

.player-name-list {
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  opacity: 0.7;
}

#no-players {
  opacity: 0.5;
}

.login-search-bar {
  max-width: 350px;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.chat-container {
  font-family: 'Arial', sans-serif;
  width: 100%;
  margin: 0;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.messages-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
  max-height: 100%;
  background-color: #e6fff5; /* Light green background for the message area */
}

.message-row {
  display: flex;
  width: 100%;
}

.message {
  margin-bottom: 5px;
  padding: 2px;
  border-radius: 10px;
  border-style: solid;
  border-color: white;
  border-width: 2px;
  display: flex;
  align-items: center;
  width: 80%;
  background-color: #d1e7dd; /* Light blue background for user's messages */
}

.my-message {
  justify-content: flex-end;
}

.other-message {
  justify-content: flex-start;
}

.message-content {
  border-radius: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.username {
  font-weight: bold;
  margin-right: 15px;
  margin-left: 15px;
}

.input-area {
  flex-shrink: 0; /* This will prevent the element from shrinking */
  margin-top: auto; /* This will push the element to the bottom */
  display: flex;
  padding: 10px;
  background-color: #d1e7dd; /* Light blue background for input area */
}

.input-area input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.input-area button {
  padding: 10px 20px;
  background-color: #0d6efd; /* Bootstrap blue for the send button */
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.input-area button:hover {
  background-color: #0b5ed7; /* A darker blue for the button on hover */
}

/* Add some responsiveness */
@media (max-width: 600px) {
  .chat-container {
    width: 100%;
  }
}
