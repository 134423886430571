body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #b9ebed;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* bold text too */
.connecting-text {
  color: white;
  font-size: 2em; /* Adjust the font size as needed */
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 2em;
  text-shadow: -2px -2px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}

@keyframes textAnimation {
  0%,
  100% {
    content: "Connecting";
  }
  33% {
    content: "Connecting.";
  }
  66% {
    content: "Connecting..";
  }
}
